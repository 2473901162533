import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import BMI from "./pages/BMI";
import BJJ from "./pages/bjj"
import Button from '@mui/material/Button';



function App() {
  return (
    <div className="App">
    <header className="App-header">
    <Router>
      
    <h1>
          HOW MUCH DO I BURN?
    </h1>
    <form action="/bmi">
          <input className='bmiBTN' type="submit" value="BMI Calculator" />
        </form>
        <form action="/bjj">
          <input className='bjjBTN' type="submit" value="🥋BJJ Calories Burned🔥"  />
        </form>
        <br/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/bmi" element={<BMI />}/>
        <Route path="/BJJ" element={<BJJ />} />
      </Routes>
    </Router>
    </header>
    </div>
  );
}

export default App;
