import './bmi.css'
import '../index.css'
import React, {useState} from 'react'
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import { Typography } from '@mui/material';

const marks = [
  {
    value: 48,
    label: "4ft",
  },
  {
    value: 12,    
    label: "1ft",
  },
  {
    value: 52,
    label: "4'4ft",
  },
  {
    value: 60,
    label: "5'",
  },
  {
    value: 64,
    label: "5'4",
  },
  {
    value: 70,
    label: "5'10",
  },
  {
    value: 74,
    label: "6'2",
  },
  {
    value: 84,
    label: "7'ft",
  },
];

function valuetext(value) {
  return `${value}`;
}

const BJJ = () => {

//state

  const [weight, setWeight] = useState(0)
  const [spar, setSpar] = useState(0)
  const [timerSpar, setTimerSpar] = useState(0)
  const [timerDrill, setTimerDrill] = useState(0)
  const [totalBurn, setTotalBurn] = useState(0)
  const [burn, setBurn] = useState('')
  const [sparBurn, setSparBurn] = useState('')
  const [drillBurn, setDrillBurn] = useState('')
  const [message, setMessage] = useState('')
  const [value, setValue] = React.useState(0);
  // this highlights all content in the input field when clicked/tapped
  //  followed by <input value={weight} onChange={(e) => setWeight(e.target.value)} onFocus={handleFocus}/>
  const handleFocus = (event) => event.target.select();

  let imgSrc=''

  let calcBurn = (event) => {
    //prevent submit
    event.preventDefault()
    
    if (weight === 0 ){
        alert('Please enter valid weight and height')
    } else {
        //•Sparring for 25 minutes: [(4.87 × weight)÷ 60] X time = __ calories
        let sparBurn = (((4.87 * weight)/60) * timerSpar)
        setBurn(sparBurn.toFixed(0))

        let drillBurn = (((3.25 * weight)/60) * timerDrill)
        setBurn(drillBurn.toFixed(1))
        
        let totalBurn = (sparBurn + drillBurn)
  
        // (w /(h*h) * 730)
        //(w / ((h*12)h*h) * 730)
  
        //logic for message
          setMessage(totalBurn)
     
      }
    }
  
    let reload = () => {
      window.location.reload()
    }
    function calculateValue(value) {
      return value
    }

  return (
    <div className='container'>
    <h2> BJJ Calories Burned</h2>
    <form onSubmit={calcBurn}>
      <div>
        <label>Your weight(lb):</label>
        <input value={weight} onChange={(e) => setWeight(e.target.value)} onFocus={handleFocus}/>
      </div>
      <div>
        <label>Minutes of</label>
        <br/>
        <label>(intentional)Sparring:</label>
        <input value={timerSpar} onChange={(event) => setTimerSpar(event.target.value)} onFocus={handleFocus}/>
      </div>
      <div>
        <label>Minutes of drilling:</label>
        <input value={timerDrill} onChange={(event) => setTimerDrill(event.target.value)} onFocus={handleFocus}/>
      </div>
      <div>
      <div>

      </div>
      </div>
      <div>
        <button className='btn' type='submit'>Submit</button>
        <button className='btn2' onClick={reload} type='submit'>Reset</button>
      </div>
      </form>
      <div>
        <h3>≈ calories burned: {sparBurn}</h3>
        <p>{message}</p>
      </div>

      <div className='img-container'>
        <img src={imgSrc} alt=''></img>
      </div>
  </div>
  )
}

export default BJJ