import './bmi.css'
import '../index.css'
import React, {useState} from 'react'
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import { Typography } from '@mui/material';

const marks = [
  {
    value: 48,
    label: "4ft",
  },
  {
    value: 12,    
    label: "1ft",
  },
  {
    value: 52,
    label: "4'4ft",
  },
  {
    value: 60,
    label: "5'",
  },
  {
    value: 64,
    label: "5'4",
  },
  {
    value: 70,
    label: "5'10",
  },
  {
    value: 74,
    label: "6'2",
  },
  {
    value: 84,
    label: "7'ft",
  },
];

function valuetext(value) {
  return `${value}`;
}

const BMItest = () => {

//state

  const [weight, setWeight] = useState(0)
  const [height, setHeight] = useState(0)
  const [bmi, setBmi] = useState('')
  const [message, setMessage] = useState('')
  const [value, setValue] = React.useState(0);
  // this highlights all content in the input field when clicked/tapped
  //  followed by <input value={weight} onChange={(e) => setWeight(e.target.value)} onFocus={handleFocus}/>
  const handleFocus = (event) => event.target.select();

  let imgSrc=''

  let calcBmi = (event) => {
    //prevent submit
    event.preventDefault()
    
    if (weight === 0 || height === 0){
        alert('Please enter valid weight and height')
    } else {
        //let Nheight = (height * 12)
        let bmi = (weight / (height * height) * 730)
        setBmi(bmi.toFixed(1))
  
        // (w /(h*h) * 730)
        //(w / ((h*12)h*h) * 730)
  
        //logic for message
  
     if (bmi <20.9) {
          setMessage('You are underweight')
    } else if (bmi >= 21 && bmi <28.6){
          setMessage('You are in a healthy weight range')
    } else {
          setMessage('you are "overweight"')
        }
      }
    }
  
    let reload = () => {
      window.location.reload()
    }
    function calculateValue(value) {
      return value
    }

  return (
    <div className='container'>
    <h2> BMI CALC</h2>
    <form onSubmit={calcBmi}>
      <div>
        <label>Your weight(lb):</label>
        <input value={weight} onChange={(e) => setWeight(e.target.value)} onFocus={handleFocus}/>
      </div>
      <div>
        <label>Height(inches):</label>
        <input value={height} onChange={(event) => setHeight(event.target.value)} onFocus={handleFocus}/>
      </div>
      <div>
      <div>
        
        {/* <Typography id="non-linear-slider" gutterBottom>
        Height in inches: {(calculateValue(height))}
        </Typography>
        <Slider value={height} onChange={(event) => setHeight(event.target.value)}
        setValue={value}
        aria-label="Custom marks"
        defaultValue={60}
        getAriaValueText={valuetext}
        step={1}
        valueLabelDisplay="auto"
        marks={marks}
        min={52}
        max={84}/> */}
      </div>
      </div>
      <div>
        <button className='btn' type='submit'>Submit</button>
        <button className='btn2' onClick={reload} type='submit'>Reset</button>
      </div>
      </form>
      <div>
        <h3>your bmi is: {bmi}</h3>
        <p>{message}</p>
      </div>

      <div className='img-container'>
        <img src={imgSrc} alt=''></img>
      </div>
  </div>
  )
}

export default BMItest